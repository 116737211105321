import React from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';

import { container, ratioBox, extraSmall, small, medium, large } from './avatar.module.scss';

import RatioImage from './ratio-image';

interface IAvatarProps {
    className?: string;
    ratioClass?: string;
    image: IGatsbyImageData | undefined;
    size?: 'extraSmall' | 'small' | 'medium' | 'large';
}

const Avatar: React.FC<IAvatarProps> = ({ className = '', image, size, ratioClass = '' }) => {
    return (
        <RatioImage
            image={image}
            className={`${container} ${className} ${getSizeClass(size)}`}
            ratioClass={`${ratioBox} ${ratioClass}`}
        />
    );
};

function getSizeClass(size: IAvatarProps['size'] | undefined): string {
    if (size === 'extraSmall') return extraSmall;
    if (size === 'small') return small;
    if (size === 'medium') return medium;
    if (size === 'large') return large;
    return '';
}

export default Avatar;
