import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { layout, breadcrumbsBox, header, authorBox, title, list } from './author-page.module.scss';
import { grid } from '../styles/grid.module.scss';
import { config } from '../config';
import { IAuthor } from '../models/author.model';
import { IPostCard } from '../models/post.model';
import { ISitePage, ISitePageContext } from '../models/site-page.model';
import { useTranslation } from '../hooks/use-translation';
import { getBreadcrumbItem } from '../utils/get-breadcrumbs';

import SEO from '../components/seo';
import MainLayout from '../layouts/main-layout';
import Author from '../components/organisms/author';
import List from '../components/organisms/list';
import PostCard from '../components/molecules/post-card';
import BulletTitle from '../components/atoms/bullet-title';

const { translationKeys } = config;

interface IAuthorPageProps {
    readonly data: {
        author: IAuthor;
        allPost: { edges: { node: IPostCard }[] };
        blogSitePage: ISitePage;
    };
    readonly pageContext: ISitePageContext;
}

const AuthorPage: React.FC<IAuthorPageProps> = ({ data, pageContext }) => {
    const { author, allPost, blogSitePage } = data;
    const { paginationKeys } = pageContext;
    const { firstName, lastName } = useTranslation(author, translationKeys.author);
    const { t } = useI18next();

    const posts = allPost.edges.map(({ node }) => node);

    return (
        <>
            <SEO title={`${firstName} ${lastName}`} />
            <MainLayout
                className={layout}
                breadcrumbsConfig={{
                    items: [
                        getBreadcrumbItem(blogSitePage.context),
                        getBreadcrumbItem(author, ['firstName', 'lastName']),
                    ],
                    className: breadcrumbsBox,
                }}
            >
                <div className={`${grid} ${header}`}>
                    <Author author={author} className={authorBox} layout="page" NameTag="h1" />
                </div>
                {posts.length > 0 && (
                    <>
                        <BulletTitle className={title}>{t('authorPage.list.header')}</BulletTitle>
                        <List className={list} paginationKeys={paginationKeys}>
                            {posts.map((post) => {
                                return (
                                    <li key={`post-card-${post.slug}`}>
                                        <PostCard post={post} />
                                    </li>
                                );
                            })}
                        </List>
                    </>
                )}
            </MainLayout>
        </>
    );
};

export const query = graphql`
    query($language: String!, $profileId: Int!, $postIds: [Int!]) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        author(profileId: { eq: $profileId }) {
            ...authorFields
        }

        allPost(
            filter: { articleId: { in: $postIds } }
            sort: { fields: publishedAt, order: DESC }
        ) {
            edges {
                node {
                    ...postCardFields
                }
            }
        }

        blogSitePage: sitePage(context: { pageKey: { eq: "blog" } }) {
            ...sitePageFields
        }
    }
`;

export default AuthorPage;
