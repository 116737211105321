import React from 'react';
import { IAuthor } from '../../models/author.model';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import Link from '../../../plugins/gatsby-plugin-ap-i18next/src/link';
import { getImage } from 'gatsby-plugin-image';

import { container, page, avatar, name, info, link } from './author.module.scss';
import { config } from '../../config';
import { useTranslation } from '../../hooks/use-translation';
import { getMediaWithRelation } from '../../utils/get-relation';

import Avatar from '../atoms/avatar';
import ArrowButton from '../atoms/arrow-button';

const { translationKeys, relations } = config;

interface IAuthorProps {
    className?: string;
    NameTag?: React.ElementType;
    author: IAuthor;
    layout?: 'post' | 'page';
}

const Author: React.FC<IAuthorProps> = ({
    className = '',
    NameTag = 'h2',
    author,
    layout = 'post',
}) => {
    const { t } = useI18next();
    const { firstName, lastName, bio } = useTranslation(author, translationKeys.author);
    const mediaItem = getMediaWithRelation(author.media, relations.mainImage);
    const image = mediaItem?.remoteImage && getImage(mediaItem.remoteImage);

    return (
        <div
            className={`
                ${container} 
                ${layout === 'page' ? page : ''}
                ${className}
            `}
        >
            <div className={avatar}>
                <Avatar image={image} {...(layout === 'page' ? { size: 'large' } : {})} />
            </div>
            <NameTag className={name}>
                {firstName} {lastName}
            </NameTag>
            {bio && <p className={info}>{bio}</p>}
            {layout !== 'page' && (
                <Link className={link} to={author.slug}>
                    <span>{t('button.see')}</span>
                    <ArrowButton rotateDeg={180} as="element" />
                </Link>
            )}
        </div>
    );
};

export default Author;
