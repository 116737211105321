import React from 'react';

import { title } from './bullet-title.module.scss';

interface IBulletTitleProps {
    className?: string;
    Tag?: React.ElementType;
}

const BulletTitle: React.FC<IBulletTitleProps> = ({ className = '', Tag = 'h2', children }) => {
    return <Tag className={`${title} ${className}`}>{children}</Tag>;
};

export default BulletTitle;
